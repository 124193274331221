body {
  display: block;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
  margin: auto;
}

.App{
  max-width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

pre {
  color: grey;
  white-space: pre-wrap;       
  white-space: -moz-pre-wrap;  
  white-space: -pre-wrap;      
  white-space: -o-pre-wrap;    
  word-wrap: break-word;
  font-size: 11px;
}

#stack {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
